<!-- ============================================================== -->
<!-- Topbar header - style you can find in pages.scss -->
<!-- ============================================================== -->
<header class="topbar">
    <nav class="navbar top-navbar navbar-expand-md navbar-light">
        <!-- ============================================================== -->
        <!-- Logo -->
        <!-- ============================================================== -->
        <div class="navbar-header text-center p-0 m-0">
            <a class="navbar-brand p-0 m-0" routerLink="./uco" *ngIf="usuario.role == 'DRIVER_ROLE'">
                <b class="w-logo"><img src="./assets/images/kite-brand.png" alt="homepage" class="img-fluid" /></b>
            </a>
            <a class="navbar-brand p-0 m-0" routerLink="./" *ngIf="usuario?.role != 'DRIVER_ROLE'">
                <b class="w-logo"><img src="./assets/images/kite-brand.png" alt="homepage" class="img-fluid" /></b>
            </a>
        </div>
        <!-- ============================================================== -->
        <!-- End Logo -->
        <!-- ============================================================== -->
        <div class="navbar-collapse">
            <!-- ============================================================== -->
            <!-- toggle and nav items -->
            <!-- ============================================================== -->
            <ul class="navbar-nav mr-auto">
                <!-- This is  -->
                <!-- <li class="nav-item"> <a class="nav-link nav-toggler hidden-md-up waves-effect waves-dark" href="javascript:undefined"><i class="ti-menu"></i></a> </li> -->
                <!-- <li class="nav-item"> <a class="nav-link sidebartoggler hidden-sm-down waves-effect waves-dark" href="javascript:undefined"><i class="ti-menu"></i></a> </li> -->
                <li class="nav-item hidden-sm-down"></li>
            </ul>
            <!-- ============================================================== -->
            <!-- User profile and search -->
            <!-- ============================================================== -->
            <ul class="navbar-nav my-lg-0">
                <!-- ============================================================== -->
                <!-- Search -->
                <!-- ============================================================== -->
                <!-- <li class="nav-item hidden-xs-down search-box">
                    <a class="nav-link hidden-sm-down waves-effect waves-dark" href="javascript:undefined">
                        <i class="ti-search"></i>
                    </a>
                    <form class="app-search" (submit)="buscarTodo( txtTermino.value)">
                        <input #txtTermino type="text" class="form-control" placeholder="Global Search">
                        <a class="srh-btn">
                            <i class="ti-close"></i>
                        </a>
                    </form>
                </li> -->

                <!-- ============================================================== -->
                <!-- Profile -->
                <!-- ============================================================== -->
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle waves-effect waves-dark" href="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img [src]="usuario.img | imagen: 'users'" alt="user" class="profile-pic" /></a>
                    <div class="dropdown-menu dropdown-menu-right animated flipInY">
                        <ul class="dropdown-user">
                            <li>
                                <div class="dw-user-box">
                                    <div class="u-img"><img [src]="usuario.img | imagen: 'users'" alt="user"></div>
                                    <div class="u-text">
                                        <h4>{{usuario.name}}</h4>
                                        <p class="text-muted">
                                            {{usuario.email | slice:0:20}}{{(usuario.email.length > 20) ? '...': ''}}
                                        </p>
                                        <a routerLink="./perfil" class="btn btn-rounded btn-danger btn-sm">
                                          View Profile
                                        </a>
                                    </div>
                                </div>
                            </li>
                            <li role="separator" class="divider"></li>
                            <li><a routerLink="./perfil"><i class="ti-user"></i> My Profile</a></li>
                            <!--<li role="separator" class="divider"></li>
                            <li><a routerLink="account-settings"><i class="ti-settings"></i> Account Setting</a></li>-->
                            <li role="separator" class="divider"></li>
                            <li>
                                <a (click)="logout()" class="pointer" routerLink="/login">
                                    <i class="fa fa-power-off"></i> Logout
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</header>
<!-- ============================================================== -->
<!-- End Topbar header -->
<!-- ============================================================== -->