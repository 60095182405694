
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';



export abstract class Repository  {

 public apiURL = environment.base_url;

  constructor(private httpClient: HttpClient) {}


  get token(): string {
    return localStorage.getItem('token') || '';
  }

  get resHeaders() {
  return {
    headers: {
      'Content-Type': 'application/json',
      'x-token': this.token,
    }
  };
  }


  create(entity: any): Observable<any> {
    const entityJson = JSON.stringify(entity);
    return this.httpClient.post<any>(`${this.apiURL}`, entityJson, this.resHeaders);
  }
  
  update(entity: any, id: any): Observable<any> {
    const entityJson = JSON.stringify(entity);
    return this.httpClient.put<any>(`${this.apiURL}/${id}`, entityJson, this.resHeaders);
  }


  getAll(): Observable<any> {
    return  this.httpClient.get<any>(this.apiURL, this.resHeaders);
  }

  getById(id: string): Observable<any> {
    return this.httpClient.get<any>(`${this.apiURL}/${id}`, this.resHeaders);
  }

  getByName(name: string): Observable<any> {
      return this.httpClient.get<any>(this.apiURL += `/${ name }`,  this.resHeaders);
  }

  add(entity: Object):  Observable<any>  {
    const entityJson = JSON.stringify(entity);
    return this.httpClient.post<any>(`${this.apiURL}`, entityJson, this.resHeaders);
  }

  edit(entity: Object, id: string):  Observable<any>  {
    const entityJson = JSON.stringify(entity);
    return this.httpClient.put<any>(`${this.apiURL}/${id}`, entityJson, this.resHeaders);
  }

  delete(id: string): Observable<any> {
    return this.httpClient.delete<any>(`${this.apiURL}/${id}`, this.resHeaders);
  }

  getAllAsync(): Promise<any> {
    return  this.httpClient.get<any>(this.apiURL, this.resHeaders).toPromise();
  }

  getAllAsyncByCollection(collection): Promise<any> {
    return  this.httpClient.get<any>(this.apiURL += `/${collection}` ).toPromise();
  }

  getAsyncById(id: string): Promise<any> {
    return this.httpClient.get<any>(`${this.apiURL}/${id}`, this.resHeaders).toPromise();
  }

  getAsyncByParam(entity: Object): Promise<any> {
    const entityJson = JSON.stringify(entity);
    return this.httpClient.post<any>(`${this.apiURL}/query`,entityJson,this.resHeaders).toPromise();
  }

  getBuscadorAsyncByCollection(tipo: any, termino) : Promise<any> {
    return this.httpClient.get<any>(environment.base_url + `/todo/coleccion/${ tipo }/${ termino }`,  this.resHeaders).toPromise();
  }

  getProjectByGuid(guid: string): Observable<any> {
    return this.httpClient.post<any>(`${this.apiURL}/get`,{ guid: guid}, this.resHeaders);
  }


  getMasterAgreements(){
    return  this.httpClient.post<any>(this.apiURL + '/getmasters', this.resHeaders).toPromise();
  }

  resendProject(id:any){
    return this.httpClient.post<any>(this.apiURL + '/resend/'+id, {} ,this.resHeaders).toPromise();
  }

}
