import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalImagenComponent } from './widgets/modal-imagen/modal-imagen.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { AdvancedTableComponent } from './widgets/advanced-table/advanced-table.component';
import { NgbSortableHeaderDirective } from './widgets/advanced-table/sortable.directive';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { BaseInputComponent } from './forms/base-input.component';
import { ImageUploaderComponent } from './forms/image-uploader/image-uploader.component';
import { ErrorsComponent } from './forms/errors/errors.component';
import { BaseComponent } from './base/base.component';
import { ImageTableViewerComponent } from './widgets/image-table-viewer/image-table-viewer.component';
import { FormVisitPlanComponent } from './widgets/forms/form-visit-plan/form-visit-plan.component';
import { FormClientComponent } from './widgets/forms/form-client/form-client.component';
import { FormNewClientComponent } from './widgets/forms/form-new-client/form-new-client.component';
import { SignaturePadComponent } from './widgets/signature-pad/signature-pad.component';
import { TableTwoColComponent } from './widgets/tables/table-two-col/table-two-col.component';
import { TableThreeColComponent } from './widgets/tables/table-three-col/table-three-col.component';
import { TableKiteComponent } from './widgets/tables/table-kite/table-kite.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { KiteModule } from './widgets/kite/kite.module';
import { InputComponent } from './forms/input/input.component';


@NgModule({
  declarations: [
    BaseComponent,
    ModalImagenComponent,
    AdvancedTableComponent,
    NgbSortableHeaderDirective,
    SignaturePadComponent,
    FormVisitPlanComponent,
    FormClientComponent,
    TableTwoColComponent,
    TableThreeColComponent,
    TableKiteComponent,
    BaseInputComponent,
    ImageUploaderComponent,
    ErrorsComponent,
    ImageTableViewerComponent,
    FormNewClientComponent,
    InputComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    SignaturePadModule,
    PdfViewerModule,
    KiteModule,
    PipesModule,
  ],
  exports: [
    BaseComponent,
    ModalImagenComponent,
    AdvancedTableComponent,
    SignaturePadComponent,
    FormVisitPlanComponent,
    FormClientComponent,
    TableTwoColComponent,
    TableThreeColComponent,
    TableKiteComponent,
    BaseInputComponent,
    ImageUploaderComponent,
    ErrorsComponent,
    ImageTableViewerComponent,
    FormNewClientComponent,
    InputComponent
  ]
})
export class WidgetsModule { }
